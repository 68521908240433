import { Fragment, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import { PrimaryButton } from "../../components/Buttons/PrimaryButton";
import { FormInputText } from "../../components/FormInputs/Input";
import { Page } from "../../components/Page";
import { useAuth } from "../../hooks/auth";
import { useSipJs } from "../../hooks/sipJsHook";
import { useSocket } from "../../hooks/socketHook";
import { clearLs } from "../../localStorage";
import { request } from "../../services/request";
import { phoneMask, sleep } from "utils";
import sweetAlert from "../../utils/sweetAlert";
import {
  approachCall,
  getLastCallData,
  getPreTabbingStatus,
  handleInPauseScheduling,
  handleRetry,
  handleSchedulingCall,
  postponeScheduling,
} from "./helper";
import {
  AreaLead,
  AreaLeadTabbingStatus,
  AttendantsStopArea,
  AwaitingCall,
  TabbingArea,
} from "./style";

export const Dial: React.FC = () => {
  const { getPayload } = useAuth();
  const route = useHistory();
  const [tabbingStatus, setTabbingStatus] = useState<any | null>();
  const [preTabbingStatus, setPreTabbingStatus] = useState<any | null>();
  const [selectedPreTabbingStatus, setSelectedPreTabbingStatus] = useState<any | null>();
  const [pauses, setPauses] = useState<Array<string>>([]);
  const [inPause, setInPause] = useState<boolean>(false);
  const [showDateTimeInput, setShowDateTimeInput] = useState<boolean>(false);
  const [dateAg, setDateAg] = useState<string>();
  const [hourAg, setHourAg] = useState<string>();
  const [approached, setApproached] = useState(false);
  const [showApproachButton, setShowApproachButton] = useState(false);

  const {
    connect: loginInCallerAndConnectInSocket,
    messageErrorLoginCaller,
    callWithoutTabbing,
    setCallWithoutTabbing,
  } = useSocket();

  const {
    connect,
    connected,
    inCallData,
    inCall,
    setInCall,
    setInCallData,
    hangup,
    micMuted,
    muteMic,
    unmuteMic,
    handleRedial,
    ramal,
    resetStates,
    setSipInPause,
    sipData,
    setSipData,
  } = useSipJs();

  useEffect(() => {
    setTimeout(() => setShowApproachButton(true), 3500);
  }, [sipData]);

  const handleOnLogoutClick = async () => {
    let res = await request({
      method: "POST",
      path: `logout`,
      showSuccessMessage: false,
    });

    if (!res.error) {
      clearLs();
      route.push("/login");
    }
  };

  const handleSaveTabbingStatus = async (retry: boolean = false) => {
    let resAlert = await sweetAlert.confirm(
      `Deseja salvar a tabulação ${retry ? "e rediscar" : ""} ?`,
      "Sim",
      "Não"
    );
    if (!resAlert) return;

    if (!callWithoutTabbing && inCall) hangup();

    if (retry) {
      resetStates();
    }

    const tabbingStatusSelector = approached ? tabbingStatus : selectedPreTabbingStatus; 
    let callDataNotApproached = {};

    if (!approached) {
      callDataNotApproached = await getLastCallData();
    }

    const callDataSelector = approached ? inCallData : callDataNotApproached;

    let res: { error: string; };

    try {
      res = await request({
        method: "POST",
        path: `${callDataSelector._id}/save-tabbing-status`,
        data: {
          tabbingStatus: tabbingStatusSelector,
          ag: hourAg && dateAg ? { hourAg, dateAg } : false,
          retry,
        },
        showSuccessMessage: false,
      });
    } catch (error: any) {
      sweetAlert.error(error.error.errors[0]);
      return;
    }


    try {
      let schedulingResponse = await request({
        method: "GET",
        path: `get-schedule-date`,
        showSuccessMessage: false,
        showErrorMessage: false,
      });

      if (!!schedulingResponse.client) {
        let schedulingAlert = await sweetAlert.confirm(
          `${schedulingResponse.client.name} | ${phoneMask(
            schedulingResponse.webhookCall.phone
          )} | ${schedulingResponse.tabbing.scheduledDate}`,
          "Ligar agora",
          "Adiar",
          "Você tem uma ligação agendada em menos de cinco minutos",
          async () => {
            await handleSchedulingCall(
              schedulingResponse._id
            );
            handleRetry(
              true,
              callWithoutTabbing,
              setInCall,
              setInCallData,
              setTabbingStatus,
              setCallWithoutTabbing,
              setApproached,
              setSipData,
              setSelectedPreTabbingStatus,
              inPause,
            );
          },
          async () => {
            if (!res.error) {
              postponeScheduling(
                schedulingResponse._id,
                tabbingStatus
              );
              handleRetry(
                retry,
                callWithoutTabbing,
                setInCall,
                setInCallData,
                setTabbingStatus,
                setCallWithoutTabbing,
                setApproached,
                setSipData,
                setSelectedPreTabbingStatus,
                inPause,
              );
            }
          }
        );

        setShowApproachButton(false);
        if (!schedulingAlert) return;
        return;
      }
    } catch {
      console.log("Ocorreu um erro ao solicitar o agendamento pessoal");
    }

    if (!res.error) {
      handleRetry(
        retry,
        callWithoutTabbing,
        setInCall,
        setInCallData,
        setTabbingStatus,
        setCallWithoutTabbing,
        setApproached,
        setSipData,
        setSelectedPreTabbingStatus,
        inPause,
      );
      setShowApproachButton(false);
    }
  };

  const handleHangupButton = async (ignoreAlert: boolean = false) => {
    if (!ignoreAlert) {
      let resAlert = await sweetAlert.confirm(
        "Deseja desligar a chamada?",
        "Sim",
        "Não"
      );
      if (!resAlert) return;

      hangup();
    }
  };

  const handleMuteDesmuteMic = async () => {
    if (!micMuted) {
      muteMic();
      return;
    }

    unmuteMic();
  };

  const verifyUserUseWebSoftphone = async () => {
    let res = await request({
      method: "GET",
      path: `caller/verify-user-use-websoftphone`,
      showSuccessMessage: false,
      showLoading: false,
    });
    console.log(res);

    await getPreTabbingStatus(setPreTabbingStatus);

    if (!res.error) {
      if (!res.softphoneWeb) {
        loginInCallerAndConnectInSocket();
      } else {
        const asteriskUrl =
          window.location.href.indexOf("stage") !== -1
            ? "kainos.gvctelecom.com.br:9113"
            : "kainos.gvctelecom.com.br:9113";
        connect({
          ws: "wss://" + asteriskUrl + "/ws",
          sipUri: `sip:${res.ramal}@${asteriskUrl}`,
          password: `${res.ramal}@xyz`,
          realm: asteriskUrl.split(":")[0],
          displayName: res.ramal,
        });

        try {
          let schedulingResponse = await request({
            method: "GET",
            path: `get-schedule-date`,
            showSuccessMessage: false,
            showErrorMessage: false,
          });
          if (!!schedulingResponse.client && !callWithoutTabbing) {
            let schedulingAlert = await sweetAlert.confirm(
              `${schedulingResponse.client.name} | ${phoneMask(
                schedulingResponse.webhookCall.phone
              )} | ${schedulingResponse.tabbing.scheduledDate}`,
              "Ligar agora",
              "Adiar",
              "Você tem uma ligação agendada em menos de cinco minutos",
              async () => {
                await handleSchedulingCall(
                  schedulingResponse._id
                );
                handleRetry(
                  true,
                  callWithoutTabbing,
                  setInCall,
                  setInCallData,
                  setTabbingStatus,
                  setCallWithoutTabbing,
                  setApproached,
                  setSipData,
                  setSelectedPreTabbingStatus,
                  inPause
                );
              },
              async () => {
                if (!res.error) {
                  await postponeScheduling(
                    schedulingResponse._id,
                    tabbingStatus
                  );
                  await sleep(3000);
                  handleRemovePause();
                }
              }
            );

            if (!schedulingAlert) return;
            return;
          }
          await sleep(5000);
          handleRemovePause();
        } catch {
          console.log("Ocorreu um erro ao solicitar o agendamento pessoal");
        }
      }
      console.log(res);
    }
  };

  const getAttendantsPauses = async () => {
    let pauses = await request({
      method: "GET",
      path: `attendant-pauses`,
      showSuccessMessage: false,
      showLoading: false,
    });

    if (!pauses.error) {
      setPauses(pauses);
    }
  };

  const handleInPause = async (status: string) => {
    setSipInPause(true);

    try {
      await request({
        method: "POST",
        path: `set-pause`,
        showSuccessMessage: true,
        showLoading: true,
        data: {
          pause: status,
        },
      });

      setInPause(true);
    } catch {
      setInPause(false);
      setSipInPause(false);
    }
  };

  const handleRemovePause = async () => {
    try {
      await request({
        method: "POST",
        path: `remove-pause`,
        showSuccessMessage: false,
        showLoading: false,
        data: {},
      });

      setInPause(false);
      setSipInPause(false);
    } catch {
      setInPause(true);
      setSipInPause(true);
    }
  };

  useEffect(() => {
    if (messageErrorLoginCaller) {
      sweetAlert.error(messageErrorLoginCaller);
    }
  }, [messageErrorLoginCaller]);

  useEffect(() => {
    verifyUserUseWebSoftphone();
    getAttendantsPauses();
  }, []); //eslint-disable-line

  useEffect(() => {
    if (
      tabbingStatus &&
      tabbingStatus !== "" &&
      (tabbingStatus.type === "ag-base" || tabbingStatus.type === "ag-pessoal")
    ) {
      setShowDateTimeInput(true);
    } else {
      setShowDateTimeInput(false);
    }
  }, [tabbingStatus]);

  useEffect(() => {
    if (callWithoutTabbing) {
      setInCallData(callWithoutTabbing);
      setApproached(true);
      setInCall(false);
    }
  }, [callWithoutTabbing]);

  useEffect(() => {
    if (connected) {
      loginInCallerAndConnectInSocket();
    }
  }, [connected]);

  const phoneChooser = useMemo(() => {
    if (!!sipData?.phone) return sipData?.phone;
    if (!!inCallData?.webhook?.phone) return inCallData?.webhook?.phone;

    return "Não listado";
  }, [inCallData?.webhook?.phone, sipData?.phone]);

  return (
    <Page
      header={`Olá, ${getPayload().name} - Ramal: ${
        ramal ? ramal : ""
      } versão: ${process.env.REACT_APP_VERSION}`}
    >
      <Fragment>
        {!sipData && (
          <PrimaryButton text="sair" onClick={handleOnLogoutClick} />
        )}
      </Fragment>
      <Fragment>
        {(!sipData && !inCallData) && !messageErrorLoginCaller && ramal && !inPause && (
          <AwaitingCall>
            <p>Aguardando chamada...</p>
          </AwaitingCall>
        )}

        {inPause && !sipData && !inCallData && (
          <AwaitingCall>
            <p>Em pausa...</p>
          </AwaitingCall>
        )}

        {messageErrorLoginCaller ||
          (!ramal && !sipData && (
            <AwaitingCall>
              <p>Conectando...</p>
            </AwaitingCall>
          ))}
        {(sipData || callWithoutTabbing) && (
          <Fragment>
            {(sipData || callWithoutTabbing) && (
              <Fragment>
                <AreaLeadTabbingStatus>
                  <AreaLead>
                    <p>Telefone: {phoneChooser}</p>
                    {Object.keys(sipData?.info || []).map((k) => {
                      return (
                        <p style={{ width: "100%" }} key={k}>
                          {k}: {sipData.info[k]}
                        </p>
                      );
                    })}
                    {approached && Object.keys(inCallData?.lead?.data || []).map((k) => {
                      return (
                        <p style={{ width: "100%" }} key={k}>
                          {k}: {inCallData.lead.data[k]}
                        </p>
                      );
                    })}
                  </AreaLead>
                  <TabbingArea>
                  {approached ?  <Select
                      options={inCallData?.client?.tabbingStatus?.map(
                        (t: any) => {
                          return {
                            value: t,
                            label: t.status,
                          };
                        }
                      )}
                      value={tabbingStatus}
                      placeholder="Selecione um status de tabulação"
                      onChange={(v) =>
                        setTabbingStatus(
                          v ? { ...v.value, label: v.label } : null
                        )
                      }
                    />
                    
                  : <Select
                      options={preTabbingStatus?.map(
                        (t: any) => {
                          return {
                            value: t,
                            label: t.status,
                          };
                        }
                      )}
                      value={selectedPreTabbingStatus}
                      placeholder="Selecione um status de pré tabulação"
                      onChange={(v) =>
                        setSelectedPreTabbingStatus(
                          v ? { ...v.value, label: v.label } : null
                        )
                      }
                    />
                  }
                    {showDateTimeInput && (
                      <Fragment>
                        <FormInputText
                          type="date"
                          label="Data"
                          name="date"
                          onChange={(v: string) => setDateAg(v)}
                        />
                        <FormInputText
                          type="time"
                          label="Hora"
                          name="time"
                          onChange={(v: string) => setHourAg(v)}
                        />
                      </Fragment>
                    )}

                    {!inCall && <PrimaryButton
                      text="Salvar tabulação"
                      onClick={handleSaveTabbingStatus}
                    />}
                    {((tabbingStatus && tabbingStatus.type === "rediscar")
                    || (selectedPreTabbingStatus && selectedPreTabbingStatus.type === "rediscar")) && !inCall && (
                      <PrimaryButton
                        text="Salvar tabulação e rediscar"
                        onClick={() => handleSaveTabbingStatus(true)}
                      />
                    )}
                  </TabbingArea>
                </AreaLeadTabbingStatus>
              </Fragment>
            )}
            {inCall && !callWithoutTabbing && <p>Ligação em andamento.</p>}
            {!inCall && !callWithoutTabbing && (
              <p>
                <b>Ligação desligada</b>
              </p>
            )}
            {inCall && !callWithoutTabbing && (
              <PrimaryButton
                style={{ marginRight: "10px" }}
                text="Desligar chamada"
                onClick={handleHangupButton}
              />
            )}
            {inCall && !callWithoutTabbing && (
              <PrimaryButton
                style={{ marginRight: "10px" }}
                text={!micMuted ? "Mutar microfone" : "Desmutar microfone"}
                onClick={handleMuteDesmuteMic}
              />
            )}
            {inCall && !approached && !callWithoutTabbing && showApproachButton && (
              <PrimaryButton
                text={"Abordar"}
                onClick={() => {
                  approachCall(setApproached, setInCallData)
                }}
              />
            )}
          </Fragment>
        )}
      </Fragment>
      <Fragment>
        <AttendantsStopArea>
          {!inPause && !inCall && (
            <Select
              options={pauses.map((t: any) => {
                return {
                  value: t,
                  label: t,
                };
              })}
              placeholder="Selecione uma pausa"
              onChange={(v) => handleInPause(v ? v.value : null)}
            />
          )}
          {inPause && !inCall && (
            <PrimaryButton text="Sair da pausa" onClick={handleRemovePause} />
          )}
        </AttendantsStopArea>
      </Fragment>
    </Page>
  );
};
